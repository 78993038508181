<template>
  <div>
    <v-container>
      <v-row v-if="planDoc">
        <v-col cols="12" md="8" sm="8">
          <div style="font-size: 50px;font-weight: bold;color: lightblue">{{ $t('subscription').toUpperCase() }}</div>
          <h1>{{ $t('bayonApp') }}</h1>

          <br>
          <div style="text-align: center;max-width: 800px">
            <h2 style="color: #6c2c73">{{ planDoc.name }}</h2>
            <h4><p>{{ planDoc.advertiseNote }}</p></h4>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="text-align: left">{{ $t('numProperty') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>{{ planDoc.numProperty }}</v-list-item-action>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="text-align: left">{{ $t('numRenew') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>{{ planDoc.numRenew }}</v-list-item-action>
            </v-list-item>


            <v-list-item v-if="planDoc.name==='Agency' || planDoc.name==='Developer'">
              <v-list-item-icon>
                <v-icon color="green">mdi-checkbox-marked-circle</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title style="text-align: left">{{ $t('numAgent') }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>{{ planDoc.numAgent }}</v-list-item-action>
            </v-list-item>

          </div>
          <v-dialog
              v-model="dialog"
              width="800px"
              persistent
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  large
                  v-bind="attrs"
                  class="ma-2"
                  outlined
                  color="indigo"
                  v-on="on"
              >
                លក្ខខណ្ឌក្នុងការផ្សព្វផ្សាយ
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5" style="font-family: 'Khmer OS Muol Light'">លក្ខខណ្ឌក្នុងការផ្សព្វផ្សាយ</span>
              </v-card-title>
              <v-card-text>
                <p style="font-family: 'Khmer OS Muol Light'"> ១ គោលបំណងរបស់ BAYON APP
                  ក្នុងការកំណត់ច្បាប់ផ្សព្វផ្សាយ៖</p>
                <p style="font-family: 'Khmer OS Battambang'">
                  BAYON APP មានគោលបំណងផ្តល់សេវាផ្សព្វផ្សាយដល់អ្នកប្រើប្រាស់ អេប មានដូចជា៖(ម្ចាស់អចលនទ្រព្យ,
                  ភ្នាក់ងារអចលនទ្រព្យ, ក្រុមហ៊ុនអចលនទ្រព្យ, អ្នកវិនិយោគ, អាចផ្សព្វផ្សាយ ទិញ,លក់និងជួល អចលនទ្រព្យ
                  គ្រប់ប្រភេទ ដែលមានចែងក្នុងលក្ខខណ្ឌ (២) ដើម្បីចូលរួមកាត់បន្ថយហានិភ័យ នៅក្នុងការផ្សព្វផ្សាយពាណិជ្ជកម្ម
                  អនឡាញ ដើម្បីអោយមានប្រសិទ្ធិភាព, និងសុវត្ថិភាព, អ្នកប្រើប្រាស់ទាំងអស់ ត្រូវគោរពតាមលក្ខខណ្ឌ បទបញ្ញាតិ្ត
                  របស់ BAYON APP និងច្បាប់របស់ប្រទេសកម្ពុជា។
                </p>
                <p style="font-family: 'Khmer OS Muol Light'"> ២ លក្ខខណ្ឌអនុវត្តក្នុងការ ផ្សព្វផ្សាយ៖</p>
                <p style="font-family: 'Khmer OS Battambang'">
                  អ្នកផ្សព្វផ្សាយទាំងអស់ត្រូវគោរពតាមច្បាប់រដ្ឋ និងអនុវត្តតាមលក្ខខណ្ឌនៃការផ្សព្វផ្សាយរបស់ BAYON APP ។
                  មានតែប្រភេទអចលនទ្រព្យដែលមានចែងក្នុងលក្ខខណ្ឌទី២នេះតែប៉ុណ្ណោះដែលត្រូវបានអនុញ្ញាត្តិអោយផ្សព្វផ្សាយ
                  នៅក្នុងកម្មវិធីបាយ័នអេប៖
                  ដី, ផ្ទះ, ខុនដូរ, ឃ្លាំង, អគារ, ការិយ៉ាល័យ, អាផាតមិន, ហាង, ភោជនីដ្ឋាន, សណ្ឋាគារ, សំណង់។
                </p>
                <p style="font-family: 'Khmer OS Muol Light'">លក្ខខណ្ឌនៃការ ទទួលខុសត្រូវ៖</p>
                <p style="font-family: 'Khmer OS Battambang';margin-left: 30px;">
                  ២-១ BAYON APP មិនទទួលខុសត្រូវចំពោះរាល់ហានិភ័យដែលពាក់ព័ន្ធនឹងការផ្សព្វផ្សាយពាណិជ្ជកម្ម ជួល, លក់ និងទិញ
                  អចលនទ្រព្យ រឺ សេវាកម្មដែលបានផ្សព្វផ្សាយក្នុង BAYON APP ឡើយ។ អ្នកផ្សព្វផ្សាយពាណិជ្ជកម្ម និងអ្នកទិញ
                  ត្រូវទទួល ខុសត្រូវរៀងខ្លួនរាល់ហានិភ័យ ដែលពាក់ ព័ន្ធនឹង កិច្ចព្រមព្រៀងជាមួយបទប្បញ្ញត្តិ និងច្បាប់របស់
                  ប្រទេសកម្ពុជា។<br>
                  ២-២ អ្នកផ្សព្វផ្សាយពាណិជ្ជកម្មមិនត្រូវបញ្ចូលការផ្សាយពាណិជ្ជកម្មស្ទួន មិនសមរម្យ ឬមិនត្រឹមត្រូវ
                  ឬសារផ្សព្វផ្សាយ ផលិតផលនិងសេវាកម្មដែលមានលក្ខខណៈភូតកុហក់,ក្លែងបន្លំ។
                </p>
                <p style="font-family: 'Khmer OS Muol Light'">៣ ផលវិបាកនៃការបំពានលក្ខខណ្ខក្នុងការផ្សព្វផ្សាយ៖</p>
                <p style="font-family: 'Khmer OS Battambang'">
                  អ្នកផ្សព្វផ្សាយពាណិជ្ជកម្មណាដែលរំលោភលើការហាមឃាត់ និងលក្ខខណ្ឌនៃការផ្សព្វផ្សាយ អាចជាកម្មវត្ថុនៃ
                  សកម្មភាពដូចខាងក្រោម៖<br>
                  • បិទការផ្សព្វផ្សាយទិញ,លក់ និងជួល ជាអចិន្រ្តៃយ៍។<br>
                  • រាយការណ៍ទៅកាន់អាជ្ញាធរសមស្រប និងប្រឈមមុខជាមួយច្បាប់។<br>

                </p>
                <p style="font-family: 'Khmer OS Muol Light'"> ៤ ការណែនាំដើម្បីសុវត្ថិភាពផ្ទាល់ខ្លួន៖</p>
                <p style="font-family: 'Khmer OS Battambang'">
                  អ្នកផ្សព្វផ្សាយពាណិជ្ជកម្មនិងអ្នកទិញត្រូវមានការប្រុងប្រយ័ត្នលើហានិភ័យរៀងៗខ្លួន ដើម្បីការពារសុវត្ថិភាព
                  មិនត្រូវ ធ្វើការណាត់ជួបនៅកន្លែងស្ថាត់ ដែលមិនមានសុវត្ថិភាព, ត្រូវមានគ្នាទៅជាមួយយ៉ាងតិច ២ទៅ៣នាក់។

                </p>

                <p style="font-family: 'Khmer OS Muol Light'"> ៥ ការទូទាត់សេវាផ្សព្វផ្សាយ៖</p>


                <p style="font-family: 'Khmer OS Battambang'">
                  អ្នកផ្សព្វផ្សាយពាណិជ្ជកម្ម ត្រូវធ្វើការទូទាត់សេវាតាមរយៈធនាគារ ABA BANK, ABA Mobile App, VISA Card,
                  Master Card តែប៉ុណ្ណោះ។

                </p>
                <p style="font-family: 'Khmer OS Muol Light'">៦ លក្ខខ័ណសងប្រាក់វិញ៖</p>


                <p style="font-family: 'Khmer OS Battambang'">
                  អ្នកប្រើប្រាស់អាចស្នើសុំអោយមានការសងប្រាក់វិញ យ៉ាងយូរ ៤៨ម៉ោង ក្រោយពេលបញ្ជារការ ផ្សព្វផ្សាយរបស់ខ្លួន
                  រឺស្នើសុំលុបចេញវិញនូវព័តមានរបស់ខ្លួននៃការបញ្ជាផ្សព្វផ្សាយរបស់ខ្លួន តាមរយៈលេខ ទូរស័ព្ទ 099-262715 រឺ
                  (Telegram) ។
                  ការទូទាត់សងប្រាក់ ត្រឡប់វិញ ទៅកាន់គណនីដើមរបស់ អ្នកផ្សព្វផ្សាយវិញមានរយះពេល ៣ ទៅ ៧ថ្ងៃ។

                </p>


              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialog = false"
                >
                  យល់ព្រម
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

        </v-col>
        <v-col cols="12" md="4" sm="4">
          <v-card
              class="mx-auto"
              max-width="344"
          >
            <v-card-text>
              <p class="text-h4 text--primary">
                {{ $t('yourPlan') }} <span style="float: right;color:#6c2c73">{{ planDoc.name }}</span>
              </p>
              <div style="font-weight: bold;font-size: 22px">{{ $t('subTotal') }} <span
                  style="float: right">${{ planDoc.price | formatNumber }}/{{ $t('month') }}</span></div>
              <v-divider style="margin-top: 20px;margin-bottom: 20px"></v-divider>
              <div style="font-size: 30px;font-weight: bold">{{ $t('total') }}<span
                  style="float: right">${{ planDoc.price | formatNumber }}</span></div>
              <div style="margin-top: 70px;padding-bottom: 30px; font-size: 18px;font-weight: bold">{{ $t('payBy') }}
              </div>
              <!--              target="aba_webservice"-->

              <v-form
                  :model="valid" ref="formPayment"
                  lazy-validation
                  id="aba_merchant_request"
                  method="post"
                  name="formPayment"
                  :action="abaPayWayApiUrl"
              >
                <input v-show="false" type="text" name="hash" id="hash" v-model="paymentDoc.hash">
                <input v-show="false" type="text" name="tran_id" id="tran_id" v-model="paymentDoc.tran_id">
                <input v-show="false" type="text" name="amount" id="amount" v-model="paymentDoc.amount">
                <input v-show="false" type="text" name="firstname" v-model="paymentDoc.firstName">
                <input v-show="false" type="text" name="lastname" v-model="paymentDoc.lastName">
                <input v-show="false" type="text" name="phone" v-model="paymentDoc.phone">
                <input v-show="false" type="text" name="email" v-model="paymentDoc.email">
                <input type="hidden" name="payment_option" v-model="paymentDoc.payment_option">
                <input type="hidden" name="merchant_id" v-model="paymentDoc.merchant_id">
                <input type="hidden" name="req_time" v-model="paymentDoc.req_time">
                <input type="hidden" name="req_time" v-model="paymentDoc.abaKey">
                <v-btn type="submit"
                       ref="submitPayment" name="submitPayment" v-show="false"></v-btn>

                <v-card
                    color="#f5f5f5"
                    light
                    style="cursor: pointer"
                    @click="payViaVisa(planDoc)"
                >
                  <v-list-item>
                    <v-list-item-action>
                      <img src="../assets/ic_generic_2x.png" :height="104*0.35" :width="160*0.35" alt="payment"/>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>Credit/Debit Card</v-list-item-title>
                      <v-list-item-subtitle><img src="../assets/A-3Card_2x.png" :height="40*0.4" :width="212*0.4" alt="paymentCredit"/>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
                <v-card
                    color="#f5f5f5"
                    style="margin-top: 20px;cursor: pointer"
                    light
                    @click="payViaABAPay(planDoc)"
                >
                  <v-list-item>
                    <v-list-item-action>
                      <img src="../assets/ic_ABA PAY_2x.png" :height="100*0.35" :width="160*0.35" alt="paymentAba"/>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>ABA PAY</v-list-item-title>
                      <v-list-item-subtitle>Scan to pay with ABA Mobile</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-form>
            </v-card-text>

          </v-card>

        </v-col>
      </v-row>
    </v-container>
    <!--    method="post" target="aba_webservice" action="https://payway-staging.ababank.com/api/pwbayonbappa/"-->
    <!--  <v-dialog v-model="dialogPersonalInfo" persistent max-width="800px" :fullscreen="$vuetify.breakpoint.mobile">-->
    <!--      <div id="aba_main_modal" class="aba-modal" style="display: block;">-->
    <!--        <div class="aba-modal-content">-->
    <!--          <v-form-->
    <!--              :model="valid" ref="formData"-->
    <!--              lazy-validation-->
    <!--              @submit="handleSubmit"-->
    <!--              id="aba_merchant_request"-->
    <!--              method="post"-->
    <!--              :action="abaPayWayApiUrl"-->
    <!--          >-->
    <!--            <v-card>-->
    <!--              <v-card-title>-->
    <!--                <span class="headline">{{ $t('personalInfo') }}</span>-->
    <!--                <v-spacer></v-spacer>-->
    <!--                <close-button @closeForm="dialogPersonalInfo=false" valid="false" v-shortkey="['esc']"-->
    <!--                              @shortkey.native="dialogPersonalInfo=false"></close-button>-->

    <!--              </v-card-title>-->
    <!--              <v-card-text>-->
    <!--                <v-container>-->
    <!--                  <v-row>-->
    <!--                    <v-col cols="12" sm="6" md="6">-->
    <!--                      <v-text-field-->
    <!--                          v-model="paymentDoc.firstName"-->
    <!--                          :rules="nameRules"-->
    <!--                          :label="$t('firstName')"-->
    <!--                          persistent-hint-->
    <!--                          required-->
    <!--                          :dense="dense"-->

    <!--                      ></v-text-field>-->
    <!--                    </v-col>-->
    <!--                    <v-col cols="12" sm="6" md="6">-->
    <!--                      <v-text-field-->
    <!--                          v-model="paymentDoc.lastName"-->
    <!--                          :rules="requireRules"-->
    <!--                          :label="$t('lastName')"-->
    <!--                          persistent-hint-->
    <!--                          required-->
    <!--                          :dense="dense"-->

    <!--                      ></v-text-field>-->
    <!--                    </v-col>-->
    <!--                    <v-col cols="12" sm="12" md="12">-->
    <!--                      <v-text-field-->
    <!--                          v-model="paymentDoc.email"-->
    <!--                          :rules="requireRules"-->
    <!--                          :label="$t('email')"-->
    <!--                          persistent-hint-->
    <!--                          required-->
    <!--                          :dense="dense"-->

    <!--                      ></v-text-field>-->
    <!--                    </v-col>-->
    <!--                    <v-col cols="12" sm="12" md="12">-->
    <!--                      <v-text-field-->
    <!--                          v-model="paymentDoc.phone"-->
    <!--                          :rules="requireRules"-->
    <!--                          :label="$t('phoneNumber')"-->
    <!--                          persistent-hint-->
    <!--                          type="number"-->
    <!--                          required-->
    <!--                          :dense="dense"-->

    <!--                      ></v-text-field>-->
    <!--                    </v-col>-->
    <!--                    <input v-show="false" type="text" name="hash" id="hash" v-model="paymentDoc.hash">-->
    <!--                    <input v-show="false" type="text" name="tran_id" id="tran_id" v-model="paymentDoc.tran_id">-->
    <!--                    <input v-show="false" type="text" name="amount" id="amount" v-model="paymentDoc.amount">-->
    <!--                    <input v-show="false" type="text" name="firstname" v-model="paymentDoc.firstName">-->
    <!--                    <input v-show="false" type="text" name="lastname" v-model="paymentDoc.lastName">-->
    <!--                    <input v-show="false" type="text" name="phone" v-model="paymentDoc.phone">-->
    <!--                    <input v-show="false" type="text" name="email" v-model="paymentDoc.email">-->
    <!--                    <input type="hidden" name="payment_option" v-model="paymentDoc.payment_option">-->
    <!--                  </v-row>-->
    <!--                </v-container>-->
    <!--              </v-card-text>-->
    <!--              <v-card-actions>-->
    <!--                <v-spacer></v-spacer>-->
    <!--                <next-step type="submit" :valid="valid"></next-step>-->
    <!--              </v-card-actions>-->
    <!--            </v-card>-->
    <!--          </v-form>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </v-dialog>-->


  </div>
</template>

<script>
import {provider} from "@/service/provider";
import axios from "axios";
//import NextStep from "@/components/nextStep"
//import CloseButton from "@/components/closeButton"
import {Constants} from "@/libs/constant";
import moment from "moment";

export default {
  name: "Payment",
  //components: {NextStep, CloseButton},
  data() {
    return {
      valid: true,
      cards: "",
      dense: true,
      dialog: false,
      planDoc: {},
      dialogPersonalInfo: false,
      dialogPay: false,
      type: "",
      userId: "",
      paymentDoc: {
        tran_id: "",
        amount: 10,
        type: "Subscribe",
        email: "",
        phone: "",
        payment_option: "cards",
        hash: "",
        firstName: "",
        lastName: "",
        userId: "",
        continue_success_url: "",
        merchant_id: Constants.abaMerchantId,
        req_time: moment().format("YYYYMMDDHms"),
        agentDoc: {},
        platform: "Web",
        abaKey: Constants.abaKey
      },
      nameRules: [
        v => !!v || 'Plan Name is required',
      ],
      requireRules: [
        v => v === 0 || !!v || 'is required',
      ],
      changeValue: true,
      abaPayWayApiUrl: Constants.abaPayWayApiUrl,
    }
  },
  methods: {
    insertUserPayment() {
      let vm = this;
      let planDoc = vm.planDoc;

      vm.paymentDoc.amount = planDoc.price;
      vm.paymentDoc.merchant_id = Constants.abaMerchantId;
      vm.paymentDoc.req_time = moment().format("YYYYMMDDHms");
      vm.paymentDoc.userId = vm.userId;
      vm.paymentDoc.type = "Subscribe";
      vm.paymentDoc.platform = "Web"

      let url =
          provider.baseURL + provider.insertUserPayment2;
      return new Promise((resolve, reject) => {
        axios.post(url, vm.paymentDoc, {headers: {token: provider.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.paymentDoc.hash = res.data.data.hash;
                vm.paymentDoc.tran_id = res.data.data.id;
                vm.$nextTick(() => {
                  vm.$refs.submitPayment.$el.click();
                })
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    handleSubmit() {
      // let vm=this;
      // console.log("Jol");
      // let url ="https://payway-staging.ababank.com/api/pwbayonbappa/";
      //   return new Promise((resolve, reject) => {
      //     axios.post(url, vm.paymentDoc).then(
      //         res => {
      //           console.log(res);
      //           if (res.data.code === 201) {
      //             resolve(res.data.data);
      //             console.log(res.data.data);
      //             vm.$message({
      //               message: this.$t('addSuccess'),
      //               type: 'success'
      //             });
      //
      //           } else {
      //             vm.$message({
      //               message: this.$t('addFail'),
      //               type: 'error'
      //             });
      //           }
      //         },
      //         error => {
      //           reject(error);
      //         }
      //     );
      //   });


      //let vm = this;
      // if (vm.$refs.formData.validate()) {
      //   let doc = {};
      //   let planDoc = vm.planDoc;
      //   doc.userId = localStorage.id;
      //   doc.amount = planDoc.price;
      //   doc.email = "narongsao98@gmail.com";
      //   doc.phone = localStorage.phoneNumber || "093623636";
      //   doc.type = "Subscribe";
      //   doc.paymentOption = vm.type;
      //   console.log(doc);
      //   let url =
      //       provider.baseURL + provider.subscriptionPayment;
      //   return new Promise((resolve, reject) => {
      //     axios.post(url, doc, {headers: {token: provider.token}}).then(
      //         res => {
      //           if (res.data.code === 201) {
      //             resolve(res.data.data);
      //             console.log(res.data.data);
      //             vm.$message({
      //               message: this.$t('addSuccess'),
      //               type: 'success'
      //             });
      //
      //           } else {
      //             vm.$message({
      //               message: this.$t('addFail'),
      //               type: 'error'
      //             });
      //           }
      //         },
      //         error => {
      //           reject(error);
      //         }
      //     );
      //   });
      // }
    }
    ,
    payViaVisa() {
      let vm = this;
      vm.paymentDoc.payment_option = "cards"
      vm.dialogPersonalInfo = true;
      vm.insertUserPayment();

    },
    payViaABAPay() {
      let vm = this;
      vm.paymentDoc.payment_option = "abapay"
      vm.dialogPersonalInfo = true;
      vm.insertUserPayment();

    },
    async getUserById(userId) {
      let vm = this;
      let url = provider.baseURL + `/users/byId?id=${userId}`;
      return new Promise((resolve, reject) => {
        axios.get(url, {headers: {token: provider.token}}).then(
            res => {
              if (res.data.code === 201) {
                let doc = res.data.data;
                vm.$store.state.userDoc = doc;
                localStorage.id = doc._id;
                localStorage.username = doc.username;
                localStorage.url = doc.url || localStorage.url || "";
                localStorage.userType = doc.userType || "";
                localStorage.phoneNumber = doc.phoneNumber || "";
                localStorage.fullName = doc.profile && doc.profile.fullName || "";

                vm.paymentDoc.phone = doc.phoneNumber || "093623636";
                vm.paymentDoc.email = doc.email || "narongsao98@gmail.com";
                vm.paymentDoc.firstName = (doc.profile && doc.profile.fullName || "").split(" ")[0] || "Narong";
                vm.paymentDoc.lastName = (doc.profile && doc.profile.fullName || "").split(" ")[1] || "Sao";

                if (doc.country || localStorage.country) {
                  localStorage.country = doc.country || localStorage.country;
                }
              }
            },
            error => {
              reject(error);
            }
        );
      });
    },
    getPlanById(id) {
      let vm = this;
      let url = provider.baseURL + `/plan/byId?id=${id}`;
      return new Promise((resolve, reject) => {
        axios.get(url, {headers: {token: provider.token}}).then(
            res => {
              if (res.data.code === 201) {
                vm.planDoc = res.data.data;
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }

  },
  // createSubscription() {
  //   let vm=this;
  //   let doc={};
  //
  //   doc.userId="dksalfas";
  //   doc.amount=10;
  //   doc.email="narongsao98@gmail.com";
  //   doc.phone="093623636";
  //   doc.type="Top Up";
  //   doc.paymentOption="cards";
  //   let url =
  //       provider.baseURL + provider.subscriptionPayment;
  //   return new Promise((resolve, reject) => {
  //     axios.post(url, doc, {headers: {token: provider.token}}).then(
  //         res => {
  //           if (res.data.code === 201) {
  //             resolve(res.data.data);
  //             vm.cards=res.data.data;
  //             vm.$message({
  //               message: this.$t('addSuccess'),
  //               type: 'success'
  //             });
  //
  //           } else {
  //             vm.$message({
  //               message: this.$t('addFail'),
  //               type: 'error'
  //             });
  //           }
  //         },
  //         error => {
  //           reject(error);
  //         }
  //     );
  //   });
  // }

  created() {
    let vm = this;
    vm.planDoc = vm.$route.params.planDoc;
    vm.userId = vm.$route.params.userId;
    vm.paymentDoc.agentDoc = vm.$route.params.agentDoc || {};
    if (vm.userId) {
      vm.getUserById(vm.userId);
    }
    if (!vm.$route.params.planId) {
      if (!(vm.$route.params && vm.$route.params.planDoc && vm.userId)) {
        vm.$router.push({name: "web-home"});
      }
    } else {
      vm.getPlanById(vm.$route.params.planId);
    }

  }
}

</script>